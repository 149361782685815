.alignleft, img.alignleft {
  margin-right: 1.5em;
  display: inline;
  float: left;
}
.alignright, img.alignright {
  margin-left: 1.5em;
  display: inline;
  float: right;
}
.aligncenter, img.aligncenter {
  margin-right: auto;
  margin-left: auto;
  display: block;
  clear: both;
}

.entry-content {
  p > strong {
    font-weight: 900;
  }

  margin-bottom: 20px;
  .history {
    position: relative;
    z-index : 1;

    padding-bottom:3px;
    margin-bottom:15px;
  }

  .history:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 50%; /* or 100px */
    border-bottom: 2px solid #f26334;
  }
}

figure.wp-block-image {
  margin-bottom:10px;
}

.article-content {
  margin-bottom: 20px;
  .history {
    position: relative;
    z-index : 1;

    padding-bottom:3px;
    margin-bottom:15px;
  }

  .history:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 50%; /* or 100px */
    border-bottom: 2px solid #f26334;
  }
}

span {
  &.primary {
    color:#f26334;
    font-weight: 900;
  }
}

.loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 200px;
}

.loader-inline {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 30px;
}